import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "./index";
import axios from "@/common/axios";
import { Absence } from "@/store/interfaces/absence.interface";
import { AbsenceBudget } from "@/store/interfaces/absence-budget.interface";
import moment from "moment";

const state = () => ({
  absences: [] as Absence[],
  budgetByUserId: {} as Record<string, AbsenceBudget[]>,
});

type AbsenceState = ReturnType<typeof state>;

const getters: GetterTree<AbsenceState, RootState> = {
  absences: (state) => state.absences,
  getBudgetByUserId: (state) => (userId) => state.budgetByUserId[userId],
};

const mutations: MutationTree<AbsenceState> = {
  SET_ABSENCES(state: AbsenceState, absences: Absence[]): void {
    state.absences = absences;
  },
  SET_BUDGET_BY_USER(state: AbsenceState, { budget, userId }: { budget: AbsenceBudget[]; userId: string }): void {
    state.budgetByUserId[userId] = budget;
  },
};

const actions: ActionTree<AbsenceState, RootState> = {
  async fetchMyAbsences({ commit }) {
    try {
      const response = await axios.get<Absence>(`/absence/my_absences`, { withCredentials: true });
      commit("SET_ABSENCES", response.data);
    } catch (e) {
      console.log(e);
    }
  },
  async fetchAllAbsences({ commit }) {
    try {
      const response = await axios.get<Absence[]>(`/absence/`, { withCredentials: true });
      commit("SET_ABSENCES", response.data);
    } catch (e) {
      console.log(e);
    }
  },
  async decideAbsence({ dispatch, getters }, { absence, requestStatus, responseComment = "" }) {
    await axios.patch(
      `/absence/${absence.id}`,
      {
        responseComment,
        requestStatus,
        deciderId: getters.currentUser.id,
      },
      { withCredentials: true }
    );

    await dispatch("fetchAllAbsences");
  },
  async deleteAbsence({ dispatch }, { absence }: { absence: Absence }) {
    await axios.delete(`/absence/${absence.id}`, { withCredentials: true });

    await dispatch("fetchAllAbsences");
  },
  async requestDeletingAbsence({ dispatch }, { absence }: { absence: Absence }) {
    await axios.patch(`/absence/${absence.id}/request_deleting`, { withCredentials: true });
    await dispatch("fetchMyAbsences");
  },
  async createAbsence({ dispatch }, { serializedData }) {
    await axios.post(`/absence`, serializedData, { withCredentials: true });

    await dispatch("fetchMyAbsences");
  },

  async fetchBudgetForUser({ commit }, userId) {
    const currentYear = moment().format("YYYY");

    const response = await axios.get(`/absence_budget/user/${userId}`, {
      params: { year: currentYear },
      withCredentials: true,
    });

    await commit("SET_BUDGET_BY_USER", { budget: response.data, userId });
  },
};

const absenceModule: Module<AbsenceState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default absenceModule;
