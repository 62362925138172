<template>
  <div v-cloak v-if="currentUser" id="app">
    <Navigation :user="currentUser" />
    <div class="mt-24 container mx-auto px-6 py-4">
      <router-view />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import Navigation from "../components/Navigation.vue";

export default defineComponent({
  components: {
    Navigation,
  },
  computed: {
    ...mapGetters("user", ["currentUser"]),
  },
  created() {
    if (!this.currentUser) {
      this.$router.push("/auth");
    }
  },
});
</script>
