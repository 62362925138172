import { App } from "vue";
import VCalendar from "v-calendar";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronLeft, faChevronRight, faTimes, faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { createI18n } from "vue-i18n";
import de from "@/i18n/de.json";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const initializePlugins = (app: App): App => {
  library.add(faUserSecret as any);
  library.add(faChevronLeft as any);
  library.add(faChevronRight as any);
  library.add(faTimes as any);

  const i18n = createI18n({
    locale: "de",
    fallbackLocale: "de",
    messages: {
      de: de,
    },
    datetimeFormats: {
      de: {
        date: {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        },
        monthDay: {
          month: "2-digit",
          day: "2-digit",
        },
        monthShort: {
          month: "short",
        },
        dateWeekdayPrefix: {
          weekday: "long",
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        },
        dateTime: {
          weekday: "short",
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        },
      },
    },
  });

  return app
    .use(i18n)
    .use(Toast)
    .use(VCalendar, {
      datePickerShowDayPopover: false,
      locale: "de",
    })
    .component("font-awesome-icon", FontAwesomeIcon);
};

export default initializePlugins;
