<template>
  <nav ref="navbar" class="bg-white shadow fixed w-full top-0 z-20" role="navigation">
    <div class="container mx-auto p-4 flex flex-wrap items-center md:flex-no-wrap">
      <div class="mr-2 md:mr-4">
        <a href="#" rel="home">
          <!--          <img-->
          <!--            alt="Logo"-->
          <!--            class="h-6 rounded-full"-->
          <!--            src="~@/assets/logo.png"-->
          <!--          >-->
        </a>
      </div>
      <div class="ml-auto md:hidden">
        <button class="flex items-center px-3 py-2 border rounded" type="button" @click="showNav = !showNav">
          <svg class="h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div :class="{ hidden: !showNav }" class="w-full md:w-auto md:flex-grow md:flex md:items-center">
        <ul class="flex flex-col px-4 mt-4 -mx-4 pt-4 border-t md:flex-row md:items-center md:mx-0 md:mt-0 md:pt-0 md:mr-4 lg:mr-8 md:border-0">
          <li v-if="user.isAdmin" class="mr-6 my-2 md:my-0">
            <router-link
              class="block py-1 md:py-3 pl-1 align-middle text-gray-500 no-underline hover:text-gray-900 border-b-2 border-white hover:border-indigo-600"
              to="/app/user"
            >
              <span class="pb-1 md:pb-0 text-sm">Mitarbeiter</span>
            </router-link>
          </li>
          <li v-if="user.isAdmin" class="mr-6 my-2 md:my-0">
            <router-link
              class="block py-1 md:py-3 pl-1 align-middle text-gray-500 no-underline hover:text-gray-900 border-b-2 border-white hover:border-indigo-600"
              to="/app/vacation/requests"
            >
              <span class="pb-1 md:pb-0 text-sm">Urlaubsanträge</span>
            </router-link>
          </li>
          <li class="mr-6 my-2 md:my-0">
            <router-link
              class="block py-1 md:py-3 pl-1 align-middle text-gray-500 no-underline hover:text-gray-900 border-b-2 border-white hover:border-indigo-600"
              to="/app/vacation"
            >
              <span class="pb-1 md:pb-0 text-sm">My Urlaub</span>
            </router-link>
          </li>
          <li class="mr-6 my-2 md:my-0">
            <router-link
              class="block py-1 md:py-3 pl-1 align-middle text-gray-500 no-underline hover:text-gray-900 border-b-2 border-white hover:border-indigo-600"
              to="/app/timetracking/me"
            >
              <span class="pb-1 md:pb-0 text-sm">My Timetracking</span>
            </router-link>
          </li>
          <li v-if="user.isAdmin" class="mr-6 my-2 md:my-0">
            <router-link
              class="block py-1 md:py-3 pl-1 align-middle text-gray-500 no-underline hover:text-gray-900 border-b-2 border-white hover:border-indigo-600"
              to="/app/projects/manage"
            >
              <span class="pb-1 md:pb-0 text-sm">Projektverwaltung</span>
            </router-link>
          </li>
          <li v-if="user.isAdmin" class="mr-6 my-2 md:my-0">
            <router-link
              class="block py-1 md:py-3 pl-1 align-middle text-gray-500 no-underline hover:text-gray-900 border-b-2 border-white hover:border-indigo-600"
              to="/app/timetracking/team"
            >
              <span class="pb-1 md:pb-0 text-sm">Timetracking Team</span>
            </router-link>
          </li>
        </ul>

        <ul class="flex flex-col mt-4 -mx-4 pt-4 border-t md:flex-row md:items-center md:mx-0 md:ml-auto md:mt-0 md:pt-0 md:border-0">
          <li>
            <a class="block px-4 py-1 md:p-2 lg:px-4" href="#" title="Profile">{{ fullName }}</a>
          </li>
          <li>
            <a class="block px-4 py-1 md:p-2 lg:px-4 text-purple-600" href="/auth/logout" title="Log out">Log Out</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      navbar: ref<HTMLElement>(),
    };
  },
  data() {
    return {
      showNav: false,
    };
  },
  computed: {
    fullName: function () {
      return `${this.user.firstName} ${this.user.lastName}`;
    },
  },
  mounted() {
    window.addEventListener("click", this.handleClick);
  },
  methods: {
    handleClick(e: Event) {
      if (e.target instanceof HTMLElement && !this.navbar?.contains(e.target)) {
        this.showNav = !this.showNav;
      }
    },
  },
});
</script>

<style scoped>
a.router-link-active {
  @apply text-indigo-600 border-indigo-600;
}
</style>
