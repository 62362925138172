import { GetterTree, MutationTree, ActionTree, Module } from "vuex";
import { RootState } from "./index";
import axios from "@/common/axios";

type Project = {
  id: number;
  name: string;
  isNotesRequired: boolean;
};

const state = () => ({
  projects: [] as Project[],
});

type ProjectsState = ReturnType<typeof state>;

const getters: GetterTree<ProjectsState, RootState> = {
  projects: (state) => [...state.projects].sort((a, b) => a.id - b.id) ?? [],
};

const mutations: MutationTree<ProjectsState> = {
  SET_PROJECTS(state: ProjectsState, projects: Project[]): void {
    state.projects = projects;
  },
};

const actions: ActionTree<ProjectsState, RootState> = {
  async fetchProjects({ commit }) {
    try {
      const response = await axios.get("/timetracking/projects", { withCredentials: true });
      commit("SET_PROJECTS", response.data);
    } catch (e) {
      console.error(e);
    }
  },
  async addProject({ dispatch }, project) {
    try {
      await axios.post("/timetracking/projects", project, { withCredentials: true });
      await dispatch("fetchProjects");
    } catch (e) {
      console.error(e);
    }
  },
  async editProject({ dispatch }, project) {
    try {
      await axios.put(`/timetracking/projects/${project.id}`, project, { withCredentials: true });
      await dispatch("fetchProjects");
    } catch (e) {
      console.error(e);
    }
  },
  async deleteProject({ dispatch }, project) {
    try {
      await axios.delete(`/timetracking/projects/${project.id}`, { withCredentials: true });
      await dispatch("fetchProjects");
    } catch (e) {
      console.error(e);
    }
  },
};

const projectsModule: Module<ProjectsState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default projectsModule;
